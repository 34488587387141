import React from 'react';
import { Link } from 'react-router-dom';

function Nav() {
  return (
    <nav style={navStyle}>
      <ul style={navListStyle}>
      <li><Link to="/">Home</Link></li>
      <li><a href="https://www.tyronicAI.com" target="_blank">TyronicAI</a></li>
        <li><Link to="/projects">Projects</Link></li>
        <li><Link to="/about">About Me</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><a href="public/LS_Genc_CV.pdf" target="_blank">Download CV</a></li>
      </ul>
    </nav>
  );
}

const navStyle = {
  backgroundColor: '#0288d1',
  padding: '1rem',
};

const navListStyle = {
  listStyleType: 'none',
  padding: 0,
  display: 'flex',
  justifyContent: 'space-around',
};

export default Nav;
