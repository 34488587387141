import { initializeApp } from "firebase/app";
// ... other Firebase services you need

const firebaseConfig = {
  apiKey: "AIzaSyA245q7WBT3Ama6trn_zJ_jvdMLm6mmTK4",
  authDomain: "ls-genc.firebaseapp.com",
  projectId: "ls-genc",
  storageBucket: "ls-genc.appspot.com",
  messagingSenderId: "584957282063",
  appId: "1:584957282063:web:fecd2a06ecc87ec980207a"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
