import React from 'react';
import { Link } from 'react-router-dom';
import WormholeConnect from '@wormhole-foundation/wormhole-connect';
import CustomCrossBridge from './CustomCrossBridge';  // Import your custom component

function Projects() {
  return (
    <section id="projects" style={sectionStyle}>
      <h2>Projects</h2>
      <ul style={listStyle}>
      <li><a href="https:/tyronicAI.com" target="_blank"> Community Services Portal</a></li>
        <li>
          {/* Link to the WormholePage route */}
          <Link to="/wormhole">Cross Bridge Legacy</Link>
        </li>
        <li><a href="/cb_custom.html">Cross Bridge Custom </a></li>
      </ul>
    </section>
  );
}

const sectionStyle = {
  padding: '20px',
  backgroundColor: '#f4f4f4',
  borderRadius: '8px',
  margin: '20px 0',
};

const listStyle = {
  listStyleType: 'none',
  padding: 0,
};

export default Projects;
