import React from 'react';

function About() {
  return (
    <section id="about" style={sectionStyle}>
      <h2>About Me</h2>
      <p>
        With over three decades of experience in software development and more than 15 years in management, 
        I am a seasoned professional passionate about coding and technology. 
        I have a robust background in various programming languages, including C/C++, C#, Java, Go, Python, and JavaScript, 
        and a deep understanding of software architectures and design patterns. 
        As a co-founder and CTO of Tyronic AI, I have led innovative projects in object detection and high-frequency trading. 
        My journey has also seen significant contributions at Ambeent AI, Digital Planet, and other esteemed organizations, 
        where I have driven success through leadership and technical expertise.
      </p>
      <p>Explore my website to learn more about my work, projects, and the technologies I love.</p>
    </section>
  );
}

const sectionStyle = {
  padding: '20px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  margin: '20px 0',
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
};

export default About;
