import React from 'react';

function Contact() {
  return (
    <section id="contact" style={sectionStyle}>
      <h2>Contact Me</h2>
      <p>If you'd like to get in touch, feel free to reach out through the following channels:</p>
      <ul style={listStyle}>
        <li><strong>Email:</strong> <a href="mailto:leventsezgin.genc@gmail.com">leventsezgin.genc@gmail.com</a></li>
        <li><strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/levent-sezgin-genc-0742b82/" target="_blank" rel="noopener noreferrer">
        https://www.linkedin.com/in/levent-sezgin-genc-0742b82/</a></li>
        <li><strong>GitHub:</strong> <a href="https://github.com/leventg" target="_blank" rel="noopener noreferrer">https://github.com/leventg</a></li>
        <li><strong>Twitter:</strong> <a href="https://x.com/lsgenc" target="_blank" rel="noopener noreferrer">@lsgenc</a></li>
      </ul>
    </section>
  );
}

const sectionStyle = {
  padding: '20px',
  backgroundColor: '#f4f4f4',
  borderRadius: '8px',
  margin: '20px 0',
};

const listStyle = {
  listStyleType: 'none',
  padding: 0,
};

export default Contact;
