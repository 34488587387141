import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import firebaseApp from './firebaseConfig';
import Header from './components/Header';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Home from './components/Home';
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact';
import WormholePage from './components/WormholePage'; // New page for WormholeConnect


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (firebaseApp) {
      console.log("Firebase initialized:", firebaseApp);
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <div>Loading Firebase SDK...</div>;
  }

  return (
    <Router> {/* Wrap the entire component structure in Router */}
      <Header />
      <Nav />
      <div id="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/wormhole" element={<WormholePage />} /> {/* New route */}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
