import React from 'react';

function Footer() {
  return (
    <footer style={footerStyle}>
      <p>&copy; 2024 My Personal Website</p>
    </footer>
  );
}

const footerStyle = {
  backgroundColor: '#039be5',
  color: 'white',
  textAlign: 'center',
  padding: '1rem',
  marginTop: '2rem',
};

export default Footer;
