import React from 'react';

function Home() {
  return (
    <section id="home" style={sectionStyle}>
      <h2>Welcome to Levent Sezgin Genç’s Professional Portfolio</h2>
      
      <p>
        Hello, and thank you for visiting my personal website! 
      </p>

      <p>
        My name is Levent Sezgin Genç, and I bring over three decades of experience in software development, with a focus on cutting-edge technology and strategic innovation.
      </p>
      
      
      <p>
        This platform showcases my journey and expertise in areas like high-frequency trading, object detection, and blockchain technologies. Here, you’ll find details about my latest projects, insights into my professional background, and how to reach out for potential collaborations.
      </p>
      
      <p>Feel free to explore:</p>
      
      <ul style={listStyle}>
        <li><strong>TyronicAI</strong>: TyronicAI website.</li>
        <li><strong>Projects</strong>: Learn more about my past and ongoing projects, from community service platforms to blockchain-based solutions.</li>
        <li><strong>About Me</strong>: Discover my journey, from my early career in software engineering to my current role as a co-founder and CTO.</li>
        <li><strong>Contact</strong>: Get in touch to discuss opportunities, collaborations, or just to connect!</li>
      </ul>
    </section>
  );
}

// Optional inline styling for section and list
const sectionStyle = {
  padding: '20px',
  textAlign: 'left',
  maxWidth: '800px',
  margin: '0 auto',
};

const listStyle = {
  textAlign: 'left',
  paddingLeft: '20px',
  lineHeight: '1.6',
};

export default Home;
