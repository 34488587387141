import React, { Suspense, lazy } from 'react';

// Lazy load WormholeConnect to avoid loading it until necessary
const WormholeConnect = lazy(() => import('@wormhole-foundation/wormhole-connect'));

function WormholePage() {
  return (
    <div>
      <h2>Cross Bridge Legacy Project</h2>
      <Suspense fallback={<div>Loading Wormhole Connect...</div>}>
        <WormholeConnect />
      </Suspense>
    </div>
  );
}

export default WormholePage;
